<template>
  <v-container fluid class="gala-world-view px-0 grey lighten-1">
    <div class="mt-6">
      <v-list
        v-for="(section, index) in menuSections"
        :key="'section-' + index"
        dense
        flat
        class="custom-list"
      >
        <v-subheader>{{ $t(section.title) }}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in section.items"
            :key="'item-' + i"
            @click="handleLogin(item.routePath, item.auth, item.external)"
          >
            <v-list-item-icon>
              <v-icon v-text="'$' + item.icon" color="primary"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </v-container>
</template>
<style lang="scss">
.gala-world-view {
  min-height: calc(100vh - 142px);
}
</style>
<script>
import login from "~/mixins/login";

export default {
  name: "GalaWorldHome",
  mixins: [login],
  data() {
    return {
      menuSections: [
        {
          title: "galaWorld.world",
          items: [
            {
              name: "magazine",
              title: "galaWorld.items.magazine",
              icon: "magazine",
              routePath: "/gala-world/magazine",
              auth: false
            },
            {
              name: "shoppingList",
              title: "galaWorld.items.shoppingList",
              icon: "listaSpesa",
              routePath: "/gala-world/shoppingList",
              auth: true
            },
            {
              name: "receiptLottery",
              title: "galaWorld.items.receiptLottery",
              icon: "lotteriaScotrini",
              routePath: "/gala-world/receiptLottery",
              auth: true
            },
            {
              name: "giftCard",
              title: "galaWorld.items.giftCard",
              icon: "giftCard",
              routePath: "/gala-world/giftCard",
              auth: true
            },
            {
              name: "sustainability",
              title: "galaWorld.items.sustainability",
              icon: "sostenibilita",
              routePath: "/gala-world/sostenibilita",
              auth: false
            },
            {
              name: "productRecall",
              title: "galaWorld.items.productRecall",
              icon: "richiamoProdotti",
              routePath: "/gala-world/richiamo-prodotti",
              auth: true
            }
          ]
        },
        {
          title: "galaWorld.contacts",
          items: [
            {
              name: "talkToUs",
              title: "galaWorld.items.talkToUs",
              icon: "parlaConNoi",
              routePath: "/gala-world/contact-form",
              auth: false
            },
            {
              name: "legalInformation",
              title: "galaWorld.items.legalInformation",
              icon: "informazioniLegali",
              routePath: "https://www.galasupermercati.it/privacy-note-legali",
              auth: false,
              external: true
            },
            {
              name: "whoWeAre",
              title: "galaWorld.items.whoWeAre",
              icon: "whoWeAre",
              routePath: "/gala-world/who-we-are",
              auth: false
            },
            {
              name: "corporateData",
              title: "galaWorld.items.corporateData",
              icon: "info",
              routePath: "/gala-world/corporate-data",
              auth: false
            }
          ]
        }
      ]
    };
  },
  methods: {
    async handleLogin(path, auth, external = false) {
      if (!auth || this.isAuthenticated) {
        this.openLink(path, external);
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.openLink(path, external);
        }
      }
    },
    openLink(path, external) {
      if (external) {
        if (this.$platform_is_cordova && path.indexOf("http") > -1) {
          window.cordova.InAppBrowser.open(path, "_system");
        } else {
          window.open(path, "_blank");
        }
      } else {
        this.$router.push(path);
      }
    }
  }
};
</script>
